import React from 'react'
import type { GatsbyBrowser } from 'gatsby'
import '@styles/reset.css'
import '@styles/global.scss'
import '@fontsource/noto-sans-jp/400.css'
import '@fontsource/noto-sans-jp/700.css'

const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
}) => {
  return <div className='wrapper'>{element}</div>
}

export { wrapPageElement }

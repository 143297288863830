exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-actual-buy-tsx": () => import("./../../../src/pages/actual-buy.tsx" /* webpackChunkName: "component---src-pages-actual-buy-tsx" */),
  "component---src-pages-actual-sell-tsx": () => import("./../../../src/pages/actual-sell.tsx" /* webpackChunkName: "component---src-pages-actual-sell-tsx" */),
  "component---src-pages-coming-soon-tsx": () => import("./../../../src/pages/coming-soon.tsx" /* webpackChunkName: "component---src-pages-coming-soon-tsx" */),
  "component---src-pages-company-tsx": () => import("./../../../src/pages/company.tsx" /* webpackChunkName: "component---src-pages-company-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-investment-buy-tsx": () => import("./../../../src/pages/investment-buy.tsx" /* webpackChunkName: "component---src-pages-investment-buy-tsx" */),
  "component---src-pages-investment-sell-tsx": () => import("./../../../src/pages/investment-sell.tsx" /* webpackChunkName: "component---src-pages-investment-sell-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-renovation-tsx": () => import("./../../../src/pages/renovation.tsx" /* webpackChunkName: "component---src-pages-renovation-tsx" */)
}

